<template>
  <div class="view-fullscreen bg-login">
    <div class="auth-container">
      <div class="d-flex justify-content-between align-items-start mb-4">
        <div class="text-center">
          <img
            v-if="domain && domain.includes('selgem')"
            src="@/assets/images/selgem-logo.png"
            class="auth-logo"
            alt="Selgem Logo"
          />
          <img
            v-else
            src="@/assets/images/infrasel-logo-white.png"
            class="auth-logo"
            alt="Infrasel Logo"
          />
          <p class="mb-0 text-muted">Disaster Monitoring System</p>
        </div>
        <h5 class="mb-0 mt-4">
          <span class="badge badge-dark badge-pill py-2 px-4">LOGIN</span>
        </h5>
      </div>
      <div class="form-group">
        <label for="identifier">Email</label>
        <input
          type="email"
          class="form-control"
          id="identifier"
          :disabled="isLoading"
          v-model="model.identifier"
          @keydown.enter="login()"
        />
      </div>
      <div class="form-group mb-4">
        <label for="password">Password</label>
        <input
          type="password"
          class="form-control"
          id="password"
          :disabled="isLoading"
          v-model="model.password"
          @keydown.enter="login()"
        />
      </div>
      <div class="alert alert-danger" role="alert" v-if="errorMessage">
        {{ errorMessage }}
      </div>
      <button
        class="btn btn-success btn-block px-5"
        @click="login()"
        :disabled="isLoading"
      >
        <span v-if="!isLoading"> Submit </span>
        <div class="spinner-border" v-else></div>
      </button>
      <div class="text-center px-2 py-3 mt-4">
        <img
          src="@/assets/images/powered-by-selgem.png"
          class="footer-logo d-inline-block"
          alt="Powered by SELGEM"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view-login",
  data() {
    return {
      domain: location.host,
      isLoading: false,
      errorMessage: null,
      model: {
        identifier: "",
        password: "",
      },
    };
  },
  methods: {
    async login() {
      this.errorMessage = null;

      if (!this.model.identifier || !this.model.password) {
        this.errorMessage = "Invalid email/password";
      } else {
        this.isLoading = true;

        let [userCall, userCallError] = await this.Helper.handle(
          this.API.post("auth/local", this.model)
        );

        if (!userCallError && userCall.status == 200) {
          localStorage.setItem("token", userCall.data.jwt);
          localStorage.setItem("user", JSON.stringify(userCall.data.user));

          return this.$router
            .push({
              name: "landing",
            })
            .catch(() => {});
        } else {
          this.errorMessage = userCallError.response.data.message
            .map((res) => res.messages.map((mes) => mes.message))
            .flat()
            .join(", ");
        }

        this.isLoading = false;
      }
    },
  },
};
</script>